import Link from 'next/link'
import { useRouter } from 'next/router'
import { IAdvertCard } from 'data-transfers/dto'
import { IPaginated } from 'data-transfers/responses'

import AdvertSwiper from 'components/AdvertSwiper'
import { Bounded } from 'components/Bounded'
import TrendingJobsSwiper from 'components/TrendingJobsSwiper'
import { IconArrow } from 'icons'

import useMediaQuery from 'hooks/useMediaQuery'

import { AdvertCarouselSlice } from 'types/prismic'
import { maxWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

type AdvertSlice = AdvertCarouselSlice & {
  adverts?: IPaginated<IAdvertCard>
}

const AdSlice = ({ slice }: { slice: AdvertSlice }) => {
  const isMobile = useMediaQuery(maxWidth(RESPONSIVE.sm))
  const { locale } = useRouter()

  if (!slice.adverts && !slice.primary.title) return null
  if (!slice.adverts?.data.length) return null

  return (
    <Bounded as="section" collapsible={false} className="bg-white pt-5 pb-10">
      <div className="flex w-full flex-col justify-between px-5 lg:flex-row lg:items-center">
        <div>
          <h3 className="mb-3 text-2xl font-bold md:text-5xl">
            {slice.primary.title}
            <i className="dot"></i>
          </h3>
        </div>
        <div>
          <Link
            href={locale == 'en' ? '/job-ads' : '/darbo-skelbimai'}
            className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
          >
            <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
              {slice.primary.buttonText}
            </span>
            <IconArrow />
          </Link>
        </div>
      </div>
      {slice.variation === 'bigGrid' && !isMobile ? (
        <TrendingJobsSwiper initialAdverts={slice.adverts!} />
      ) : (
        <AdvertSwiper adverts={slice.adverts.data} />
      )}
    </Bounded>
  )
}

export default AdSlice
