import Image from 'next/image'
import { PrismicLink } from '@prismicio/react'

import { Bounded, BoundedVariant } from 'components/Bounded'
import { IconArrow } from 'icons'

import { CommunitypostDocument, CommunitySectionSlice } from 'types/prismic'

type CommunitySectionDataSlice = CommunitySectionSlice & {
  communityPosts: CommunitypostDocument<string>[]
}

const CommunitySection = ({ slice }: { slice: CommunitySectionDataSlice }) => (
  <Bounded
    as="section"
    variant={BoundedVariant.Base}
    collapsible={false}
    className="[&_a]:font-semibold[&_a]:underline bg-white pb-10 [&_a]:transition-colors hover:[&_a]:text-red-550"
  >
    <div className="flex flex-col gap-x-20 gap-y-10 md:flex-row">
      <div className="flex-1">
        <h3 className="text-2xl font-bold md:text-5xl">
          {slice.primary.title}
          <i className="dot"></i>
        </h3>
        <p className="mt-5 text-gray-400">{slice.primary.description}</p>
        <div className="relative h-[400px] w-full md:h-full">
          <Image
            src={slice.primary.image.url ?? ''}
            alt={slice.primary.image.alt ?? '' + ' logo'}
            layout="fill"
            objectFit="contain"
            objectPosition="left"
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-y-8">
        <PrismicLink
          field={slice.primary.button}
          className="animated-svg mr-0 mt-2 ml-auto flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
        >
          <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
            {slice.primary.buttonText}
          </span>
          <IconArrow />
        </PrismicLink>
      </div>
    </div>
  </Bounded>
)

export default CommunitySection
