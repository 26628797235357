import { useRouter } from 'next/router'
import { useState } from 'react'
import { AdvertVisibilityType } from 'enums'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'

import AdvertCard from 'components/AdvertCard'
import { SwiperButton } from 'components/buttons'

import useAdvertsPublicInfinity from 'hooks/useAdvert/useAdvertsPublicInfinity'

import 'swiper/css/pagination'

import { ITrendingJobsSwiperProps } from './types'

import 'swiper/css'

const COUNT_SLIDES = 8
export const TrendingJobsSwiper = ({
  initialAdverts
}: ITrendingJobsSwiperProps) => {
  const { locale } = useRouter()
  const [swiper, setSwiper] = useState<SwiperClass | null>(null)
  const [enableLoading, setEnableLoading] = useState(true)

  const { data: adverts, fetchNextPage } = useAdvertsPublicInfinity({
    payload: {
      languageCode: locale,
      limit: 9,
      statuses: [AdvertVisibilityType.Active],
      orderingColumn: 'dateCreated',
      order: 'desc',
      filledPages: true
    },
    options: {
      onSuccess: () => {
        swiper?.update()
        setEnableLoading((swiper?.slides?.length ?? 0) < COUNT_SLIDES)
      },
      initialData: initialAdverts
    }
  })

  return (
    <div className="relative mx-auto max-w-[96vw] py-10">
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={(swiper) => {
          if (swiper.activeIndex > swiper.slides.length - 2 && enableLoading) {
            fetchNextPage()
          }
        }}
        spaceBetween={16}
        slidesPerView={1}
        autoHeight={true}
        style={{
          position: 'inherit',
          padding:
            typeof window === 'undefined'
              ? '0 60px'
              : window.innerWidth > 480
              ? '0 60px'
              : '0 20px',
          overflow: 'clip',
          overflowY: 'visible'
        }}
        pagination={{
          clickable: true
        }}
        modules={[Pagination, Autoplay]}
        autoplay
      >
        <div className="hidden sm:block">
          <SwiperButton
            className="bg-white pl-2 pr-2 lg:pr-4 lg:pl-0"
            type={'prev'}
          />
        </div>
        {adverts?.pages.map((chunk, index) => (
          <SwiperSlide key={index} className="fade-in-delay pb-10">
            <div
              className="grid grid-cols-3 gap-4"
              onMouseEnter={() => swiper?.autoplay.stop()}
              onMouseLeave={() => swiper?.autoplay.start()}
            >
              {chunk?.data?.map((ad) => (
                <AdvertCard
                  key={ad.id}
                  ad={ad}
                  className=" !border-black"
                  swiper={swiper}
                />
              ))}
            </div>
          </SwiperSlide>
        ))}
        <div className="hidden sm:block">
          <SwiperButton
            className="bg-white pl-2 pr-2 lg:pl-2 lg:pr-0"
            type={'next'}
          />
        </div>
      </Swiper>
    </div>
  )
}

export default TrendingJobsSwiper
