import Image from 'next/image'

import BigLink from 'components/BigLink'
import { Bounded } from 'components/Bounded'

import { HeroSlice } from 'types/prismic'

const Hero = ({ slice }: { slice: HeroSlice }) => {
  return (
    <Bounded
      as="section"
      collapsible={false}
      className="bg-white pb-5 pt-5 md:pt-12"
    >
      <div className="flex flex-col-reverse px-5 lg:flex-row lg:items-center lg:gap-x-10 xl:px-0">
        <div className="flex-[2_2_60%] lg:pb-40">
          {slice.primary.title && (
            <h1 className="mb-5 text-2xl font-bold leading-[125%] lg:text-5xl">
              {slice.primary.title}
            </h1>
          )}
          {slice.primary.paragraph && (
            <p className="mb-8 text-xl leading-6 text-gray-500">
              {slice.primary.paragraph}
            </p>
          )}
          {slice.primary.buttontext && (
            <div className="flex w-fit justify-center sm:justify-end lg:justify-start">
              <BigLink
                hrefDefault="/apply"
                buttonField={slice.primary.button}
                buttonText={slice.primary.buttontext}
              />
            </div>
          )}
        </div>
        {slice.primary.image.url && (
          <div className="relative mb-10 h-[300px] w-full sm:h-[500px] lg:flex-[4_4_100%]">
            <Image
              src={slice.primary.image.url ?? ''}
              alt={slice.primary.image.alt ?? '' + ' logo'}
              layout="fill"
              objectFit="contain"
              objectPosition="center"
              className="fade-in-delay opacity-0"
              priority={true}
            />
          </div>
        )}
      </div>
    </Bounded>
  )
}

export default Hero
