import Image from 'next/future/image'
import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import { Bounded } from 'components/Bounded'

import { SectionWithImageSlice } from 'types/prismic'

const SectionParagraph = ({ slice }: { slice: SectionWithImageSlice }) => {
  return (
    <Bounded className="pt-20">
      <section className="grid grid-cols-1 gap-x-20 px-5 pb-12 md:grid-cols-2 xl:px-0">
        <div className="">
          {slice.primary.title && (
            <h1 className="pb-16 text-3xl font-[700] sm:text-5xl">
              {slice.primary.title}
              <i className="dot" />
            </h1>
          )}
          <div className="br-margin text-lg text-gray-600">
            {slice.primary.description && (
              <PrismicRichText field={slice.primary.description} />
            )}
          </div>
        </div>
        <div className="relative aspect-square w-full">
          {slice.primary.image.url && (
            <Image
              src={slice.primary.image.url}
              alt={slice.primary.image.alt ?? ''}
              style={{ objectFit: 'contain' }}
              className=""
              fill
            />
          )}
        </div>
      </section>
    </Bounded>
  )
}

export default SectionParagraph
