import Image from 'next/future/image'
import React from 'react'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

import { Bounded } from 'components/Bounded'
import { IconArrow } from 'icons'

import { SectionWithImageAndLinkSlice } from 'types/prismic'

const SectionWithImageAndLink = ({
  slice
}: {
  slice: SectionWithImageAndLinkSlice
}) => (
  <Bounded className="pt-20">
    <section className="grid grid-cols-1 gap-x-20 px-5 pb-12 md:grid-cols-2 xl:px-0">
      <div className="">
        {slice.primary.title && (
          <h2 className="pb-16 text-3xl font-[700] sm:text-5xl">
            {slice.primary.title}
            <i className="dot" />
          </h2>
        )}
        <div className="br-margin text-lg text-gray-600">
          {slice.primary.description && (
            <PrismicRichText field={slice.primary.description} />
          )}
          {slice.primary.link && (
            <PrismicLink
              field={slice.primary.link}
              className="animated-svg mt-2 flex items-center text-lg font-semibold uppercase text-red-550 hover:text-red-600"
            >
              <span className="relative mr-2 after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-red-550 hover:after:bg-red-600">
                {slice.primary.linkText}
              </span>
              <IconArrow />
            </PrismicLink>
          )}
        </div>
      </div>
      <div className="relative aspect-square w-full">
        {slice.primary.image.url && (
          <Image
            src={slice.primary.image.url}
            alt={slice.primary.image.alt ?? ''}
            style={{ objectFit: 'contain' }}
            className=""
            fill
          />
        )}
      </div>
    </section>
  </Bounded>
)

export default SectionWithImageAndLink
