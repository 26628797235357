import Image from 'next/image'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Bounded } from 'components/Bounded'

import { SponsorsSlice } from 'types/prismic'

import 'swiper/css/autoplay'
import 'swiper/css/pagination'

import 'swiper/css'

const Sponsors = ({ slice }: { slice: SponsorsSlice }) => {
  return (
    <Bounded
      as="section"
      collapsible={false}
      className=" bg-indigo-1000 pb-6 pt-20"
    >
      <h4 className="mb-10 px-5 text-2xl font-bold text-white md:text-5xl">
        {slice.primary.title}
        <i className="dot"></i>
      </h4>
      <div className="relative my-10 mx-auto max-w-[96vw]">
        <Swiper
          spaceBetween={50}
          breakpoints={{
            0: {
              spaceBetween: 20
            },
            768: {
              spaceBetween: 30
            },
            1024: {
              spaceBetween: 50
            }
          }}
          slidesPerView="auto"
          pagination={{
            clickable: true,
            horizontalClass: 'pagination mb-[-12px]'
          }}
          modules={[Pagination, Autoplay]}
          autoplay
        >
          {slice.items.map((item) => (
            <SwiperSlide
              key={item.sponsorName}
              className="fade-in-delay px-5"
              style={{ width: '200px', height: '200px' }}
            >
              <Image
                src={item.sponsor.url ?? ''}
                alt={item.sponsor.alt ?? '' + ' logo'}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Bounded>
  )
}
export default Sponsors
